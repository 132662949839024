@import "../global.less";
@import "./form.less";

.togglebox {
	--checked-thumb-color: var(--brand-green);
	--checked-track-color: var(--brand-green-light);
	--unchecked-thumb-color: var(--brown-soft);
	// this one depends of the theme-color
	--unchecked-track-color: var(--bg-brown);

	--height-track: 2.25rem;
	--width-track: 3.75rem;
	--height-thumb: 1.75rem;
	--spacing-thumb: calc((var(--height-track) - var(--height-thumb)) / 2);

	&:has(input.theme-white) {
		--unchecked-track-color: var(--white);
	}

	position: relative;
	display: flex;
	width: 100%;
	min-width: 6rem;

	&:not(:last-child) {
		margin-bottom: var(--base-margin-sm);
	}

	label {
		margin: 0;
		max-width: 82%;
	}

	input[type="checkbox"] {
		-webkit-appearance: none;
		position: absolute;
		height: 0;
		right: 7px;
		margin: 1.5rem;

		&:focus-visible {
			+ label:after,
			+ label:before {
				outline: 2px solid var(--brand-secondary);
			}
		}

		// Track of the toggle
		+ label:before {
			background-color: var(--unchecked-track-color);
			transition: background-color 0.2s ease;
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			height: var(--height-track);
			width: var(--width-track);
			border-radius: var(--height-track);
			cursor: pointer;
		}

		// thumb of the toggle
		+ label:after {
			transition: right 0.2s;
			content: "";
			position: absolute;
			visibility: visible;
			background-color: var(--unchecked-thumb-color);
			margin: var(--spacing-thumb);
			top: 0;
			right: calc(
				var(--width-track) - var(--height-thumb) -(var(--spacing-thumb) * 2)
			);
			aspect-ratio: 1;
			height: var(--height-thumb);
			border-radius: 50%;
			cursor: pointer;
		}

		&:checked + label:before {
			transition: background-color 0.2s ease;
			background-color: var(--checked-track-color);
			border: none;
		}

		&:checked + label:after {
			transition: right 0.2s ease;
			right: 0;
			background-color: var(--checked-thumb-color);
		}

		&[disabled] {
			+ label:before,
			+ label:after {
				background: var(--input-bg-disabled);
				border-color: var(--input-checked-bg-disabled);
				border: 1px solid var(--brand-secondary-light-3);
			}

			+ label,
			+ label:before,
			+ label:after,
			&:checked + label:before,
			&:checked + label:after {
				cursor: not-allowed;
			}

			+ label {
				i[data-tooltip] {
					color: var(--brand-secondary-light-3);
					pointer-events: none;
				}
			}
		}
	}

	&.label-top {
		margin-bottom: 1.5rem;

		input[type="checkbox"] {
			left: -11px;
			top: 100%;

			+ label:before,
			+ label:after {
				left: 0;
				top: 100%;
				transition:
					0.2s ease,
					top 0;
			}

			+ label:after {
				transition: left 0.2s ease;
			}

			&:checked {
				+ label:before {
					transition: background-color 0.2s ease;
				}

				+ label:after {
					left: calc(
						var(--width-track) - var(--height-thumb) -
							(var(--spacing-thumb) * 2)
					);
					transition: left 0.2s ease;
				}
			}
		}
	}

	i[data-tooltip] {
		margin-bottom: 0;
	}
}

@media (forced-colors: active) {
	.togglebox {
		input[type="checkbox"] {
			&:focus,
			&:hover {
				+ label:after {
					border: 9px solid;
				}

				+ label:before {
					outline: 8px solid;
				}
			}
		}
	}
}
