.tooltip {
	position: relative;
	cursor: pointer;

	&:hover {
		border-color: var(--brand-secondary);
	}

	&:focus-visible,
	&:active {
		outline: 2px solid var(--brand-secondary);
	}

	&.isVisible {
		&:before,
		[role="tooltip"] {
			visibility: visible;
			opacity: 1;
		}
	}

	&:before {
		content: "";
		position: absolute;
		visibility: visible;
		font-family: var(--font-stack);
		top: -8px;
		left: 50%;
		transform: translateX(-50%);
		border-width: 0.35rem 0.375rem 0 0.375rem;
		border-style: solid;
		border-color: rgba(255, 255, 255, 1) transparent transparent transparent;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		z-index: calc(var(--tooltip-z-index, 1100) + 1);
	}

	[role="tooltip"] {
		visibility: hidden;
		position: absolute;
		font-family: var(--font-stack);
		left: 50%;
		width: max-content;
		max-width: 250px;
		top: -8px;
		transform: translateX(-50%) translateY(-100%);
		background: var(--white);
		color: var(--brand-secondary);
		text-align: center;
		font-size: 0.875rem;
		line-height: 1.5;
		white-space: normal;
		border-radius: 5px;
		pointer-events: none;
		padding: 0.75rem 1rem;
		z-index: var(--tooltip-z-index, 1100);
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
		min-width: 5rem;
	}

	&.tooltip-right {
		&:before {
			left: 100%;
			top: 50%;
			margin-left: 2px;
			transform: translateY(-50%) rotate(90deg);
		}

		[role="tooltip"] {
			left: 100%;
			top: 50%;
			margin-left: 10px;
			transform: translateX(0%) translateY(-50%);
		}
	}

	&.tooltip-bottom {
		&:before {
			top: 100%;
			margin-top: calc(var(--base-margin-sm) + 2px);
			transform: translateX(-50%) translateY(-100%) rotate(-180deg);
		}

		[role="tooltip"] {
			left: 50%;
			top: 100%;
			margin-top: calc(var(--base-margin-sm) + 2px);
			transform: translateX(-50%) translateY(0%);
		}
	}

	&.tooltip-left {
		&:before {
			left: 0%;
			top: 50%;
			margin-left: -13px;
			transform: translateY(-50%) rotate(-90deg);
		}

		[role="tooltip"] {
			left: 0%;
			top: 50%;
			margin-left: -9px;
			transform: translateX(-100%) translateY(-50%);
		}
	}

	&:focus-visible,
	&:hover {
		&:before,
		[role="tooltip"] {
			visibility: visible;
			opacity: 1;
		}
	}
}

@media screen and (max-width: @screen-sm-max) {
	.tooltip {
		[role="tooltip"] {
			max-width: calc(100vw - 3rem);
		}
	}
}

@media (forced-colors: active) {
	.tooltip {
		[role="tooltip"] {
			border: 10px solid;
		}
	}
}
