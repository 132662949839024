@import "../global.less";

@text-muted: fade(@brand-secondary, 75%);

.slab-variant(@bg-color) {
	background-color: @bg-color;

	&.slab-arrow {
		&:before {
			border: 1px solid transparent;
		}
	}
}

.slab {
	min-height: 0.5rem;
	padding: 1rem;
	margin-bottom: 1rem;
	border-radius: 1rem;
	background-color: @white;
	box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.15);

	&.slab-primary {
		.slab-variant(@brand-bg-1);
	}

	&.slab-secondary {
		.slab-variant(@brand-bg-2);
	}

	&.slab-plain {
		.slab-variant(@light-gray);
	}

	&.slab-funny {
		color: @black;
		background: repeating-linear-gradient(
			-45deg,
			#ffefc7,
			#ffefc7 10px,
			#fdc92a 10px,
			#fdc92a 15px
		);
	}

	&.slab-christmas {
		color: @white;
		background: repeating-linear-gradient(
			-45deg,
			#bb0101,
			#bb0101 10px,
			#42b864 10px,
			#42b864 15px
		);
	}

	&:last-child {
		margin-bottom: 0;
	}

	&.light {
		box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.15);
	}

	&.dark {
		box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.25);
	}

	&.flat {
		box-shadow: 1px 2px 5px rgba(0, 0, 0, 0);
	}

	&.slab-elevated {
		box-shadow: 3px 4px 4px -3px rgba(0, 0, 0, 0.75);
	}

	&.slab-well {
		box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.3);
	}

	&.slab-lg {
		padding: 1.5rem;
	}

	&.slab-sm {
		padding: 0.5rem;
	}

	&.slab-arrow {
		position: relative;
		filter: drop-shadow(1px 2px 5px rgba(0, 0, 0, 0.25));
		box-shadow: unset;

		&:after {
			content: "";
			height: 1rem;
			width: 2rem;
			background-color: inherit;
			position: absolute;
			top: -14px;
			left: 1.5rem;
			clip-path: polygon(40% 15%, 2% 90%, 78% 90%);
		}
	}

	/* stylelint-disable selector-list-comma-newline-after */
	&.slab-muted {
		color: @text-muted;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		.h1,
		.h2,
		.h3,
		.h4,
		.h5,
		.h6,
		.lead,
		p,
		span,
		small,
		mark,
		abbr,
		.initialism,
		blockquote {
			color: @text-muted;
		}
	}
	/* stylelint-enable selector-list-comma-newline-after */

	&.slab-border-success {
		border-top: 4px solid @brand-success-light-2;
	}

	&.slab-border-error {
		border-top: 4px solid var(--brand-error);
	}

	&.slab-edges {
		border-radius: 2px;
	}
}

@media (forced-colors: active) {
	.slab-variant,
	.slab {
		border: 1px solid;

		&.slab-border-success,
		&.slab-border-error {
			border-top: 4px solid;
			border-left: 1px solid;
			border-right: 1px solid;
			border-bottom: 1px solid;
		}
	}
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
	.slab {
		// Styling for new slab arrow
		&.slab-arrow {
			&:after {
				forced-color-adjust: none;
				filter: drop-shadow(1px 2px 5px rgba(0, 0, 0, 0.25));
			}

			// Styling for old slab
			&.slab-arrow {
				&:before {
					border-width: 1px 0 0 1px;
				}
			}
		}
	}
}
