@import "../global.less";

.media {
	display: flex;
	margin-bottom: 1rem;
	align-items: center;

	.media-img {
		display: flex;

		> :is(
				img,
				.material-icons,
				.material-icons-outlined,
				i[class^="at-"],
				i[class*=" at-"],
				i[class^="swepay-icon-"],
				i[class*=" swepay-icon-"]
			) {
			width: 48px;
			height: 48px;
			font-size: 3rem;
		}

		i:is([class^="at-"], [class*=" at-"]):before {
			align-self: unset;
			justify-self: unset;
		}
	}

	&.media-sm {
		.media-img {
			> :is(
					img,
					.material-icons,
					.material-icons-outlined,
					i[class^="at-"],
					i[class*=" at-"],
					i[class^="swepay-icon-"],
					i[class*=" swepay-icon-"]
				) {
				width: 40px;
				height: 40px;
				font-size: 2.5rem;
			}
		}
	}

	&.media-lg {
		.media-img {
			> :is(
					img,
					.material-icons,
					.material-icons-outlined,
					i[class^="at-"],
					i[class*=" at-"],
					i[class^="swepay-icon-"],
					i[class*=" swepay-icon-"]
				) {
				width: 56px;
				height: 56px;
				font-size: 3.5rem;
			}
		}
	}

	&.media-right {
		.media-img {
			order: 1;
		}
	}

	.media-body {
		padding: 0 0.5rem;
		display: flex;
		flex-direction: column;

		/* stylelint-disable selector-list-comma-newline-after */
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		.h1,
		.h2,
		.h3,
		.h4,
		.h5,
		.h6 {
			margin: 0;
		}
		/* stylelint-enable selector-list-comma-newline-after */

		p {
			margin: (-0.5rem * 0.5) 0 0;
		}
	}
}
