.page-footer {
	padding: 1rem 2rem 0;
	border-top: 8px solid @brand-primary;

	.page-footer-rights {
		text-align: center;
		font-family: var(--brand-headline);
		font-weight: var(--font-weight-normal);
	}
}

@media screen and (min-width: @screen-sm-min) {
	.page-footer {
		.page-footer-rights {
			text-align: initial;
		}
	}
}
