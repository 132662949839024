@import "./form.less";
@import "../global.less";

// TODO: next major release: remove support for `.radio.checkmark label i` and replace with `.radio.checkmark label .checkmark-icon` officially

.radio {
	--radio-height: 1.375rem;
	--radio-width: var(--radio-height);

	--checkmark-width: 7.5rem;
	--checkmark-heigth: 2rem;

	position: relative;
	display: flex;
	align-items: center;

	&:not(:last-child) {
		&:not(.checkmark) {
			margin-bottom: 1rem;
		}
	}

	label {
		position: relative;
		align-items: center;
		margin: 0;
		margin-inline-start: 1.75rem;
		padding-inline-start: calc(1rem / 5);
		min-height: 1.5rem;
	}

	input[type="radio"] {
		opacity: 0;
		position: absolute;

		+ label:before {
			content: "";
			position: absolute;
			visibility: visible;
			left: -1.5rem;
			height: var(--radio-height);
			width: var(--radio-width);
			background-color: var(--white);
			border: 2px solid var(--brand-secondary-light-2);
			cursor: pointer;
			border-radius: 50%;
		}

		&:checked + label:before {
			background-color: var(--white);
			background-repeat: no-repeat;
			background-image: url("../../icons/shapes/circle.svg");
			border-color: var(--brand-secondary);
		}

		&:is(:hover, :focus-visible) + label:before {
			box-shadow: 0 0 0 0.375rem var(--brand-primary-light);
			border-color: var(--brand-secondary);
			outline: 4px solid transparent;
		}

		&[disabled] {
			+ label:before {
				background-color: var(--input-bg-disabled);
				border-color: var(--input-checked-bg-disabled);
				box-shadow: none;
			}

			&:checked + label:before {
				background-color: var(--white);
				background-image: url("../../icons/shapes/circle-disabled.svg");
			}

			+ label,
			+ label:before,
			&:checked + label:before {
				cursor: not-allowed;
			}
		}
	}
}

.radio.checkmark {
	border: 2px solid transparent;
	pointer-events: none;
	width: auto;
	min-width: fit-content;
	height: auto;
	margin: 0;

	&.large {
		height: 3.5rem;
	}

	&:active {
		border: 2px solid transparent;
	}

	&:hover {
		background-color: transparent;
	}

	* {
		pointer-events: all;
	}

	label {
		outline: 1px solid var(--black);
		border-radius: var(--border-radius, 8px);
		padding: 7px 1rem;
		height: 100%;
		width: 100%;
		margin: 0;
		gap: 1rem;
		justify-content: space-between;

		&:before {
			display: none;
		}

		&
			:is(
				i[class^="at-"],
				i[class*=" at-"],
				i[class^="swepay-icon-"],
				i[class*=" swepay-icon-"],
				.material-icons
			) {
			color: inherit;
			margin-inline-end: -10px;
		}

		.checkmark-icon,
		& > i {
			order: 2;
			width: 24px;
			height: 24px;
			background-color: var(--brown-solid);
			border-radius: 50%;
			position: relative;
			aspect-ratio: 1;

			// display a checkmark icon in pure CSS
			&::before {
				content: "";
				position: absolute;
				width: 0.42rem;
				height: 0.7rem;
				border: solid var(--white);
				border-width: 0 2px 2px 0;
				transform: rotate(45deg) translate(145%, -25%);
			}
		}
	}

	// checked state
	input[type="radio"]:checked {
		& + label {
			border-radius: var(--border-radius, 8px);
			outline-width: 3px;
			outline-offset: -2px;

			// we're migrating from i to a pure CSS span.checkmark-icon. Keep <i > for now until next major release i {
			i {
				color: #000000;
			}
		}

		&:hover:not(:disabled) + label {
			background-color: var(--brown-medium);
			color: var(--white);

			.checkmark-icon,
			> i {
				background-color: var(--white);

				&::before {
					border-color: var(--brown-medium);
				}
			}
		}
	}

	// not checked state
	input[type="radio"]:not(:checked) + label {
		.checkmark-icon,
		i {
			visibility: hidden;
		}
	}

	// disabled state
	input[type="radio"]:disabled {
		border: 2px solid transparent;
		margin: auto;

		& + label {
			color: var(--brown-light);
			outline: 1px solid var(--brown-light);
		}

		&:checked + label {
			outline: 0;
			background-color: var(--gray);
			color: var(--brown-light);

			.checkmark-icon,
			& > i {
				background-color: var(--brown-light);

				&::before {
					border-color: var(--gray);
				}
			}

			// we're migrating from i to a pure CSS span.checkmark-icon. Keep <i> for now until next major release
			i {
				visibility: hidden;
			}
		}
	}

	// not disabled state
	input[type="radio"]:not(:disabled):hover + label {
		background-color: var(--brown-medium);
		color: var(--white);
		border-radius: var(--border-radius, 8px);
		border: 0 solid transparent;
		outline: 1px solid var(--brown-medium);
		box-shadow: none !important;

		// we're migrating from i to a pure CSS span.checkmark-icon. Keep <i> for now until next major release
		i {
			color: #f0f0f0;
		}
	}

	input[type="radio"] {
		&:focus-visible + label {
			box-shadow: 0 0 0 0.3rem var(--brand-secondary-light-3);
			border-color: var(--brand-secondary);
		}

		&:not(:checked) + label {
			i {
				visibility: hidden;
			}
		}
	}

	&:not(.large) label {
		gap: calc(1rem - (24px - 16px));

		& .checkmark-icon,
		& > i {
			transform: scale(0.67);
		}
	}

	&:has(.subtext) {
		width: 248px;
		height: auto;

		input[type="radio"] + label {
			display: grid;
			width: 100%;
			height: fit-content;
			padding: 1rem;
			grid-template-areas:
				"label-title checkmark"
				"label-subtext checkmark";
			grid-template-columns: 1fr auto;
			gap: 0 1rem;
			grid-template-rows: auto auto;

			span {
				margin: 0;
			}

			span:not(.subtext, .checkmark-icon) {
				grid-area: label-title;
				font-weight: var(--font-weight-bold);
			}

			.subtext {
				grid-area: label-subtext;
				font-weight: var(--font-weight-normal);
			}

			.checkmark-icon {
				grid-area: checkmark-icon;
				grid-row: 1 / -1;
				transform: none;
			}
		}

		input[type="radio"]:not(:checked) + label :is(.checkmark-icon, i) {
			visibility: hidden;
		}
	}
}

fieldset {
	&.radio-group {
		.radio-row {
			display: inline-grid;
			gap: 1rem;
			grid-auto-flow: column;
			grid-auto-columns: 1fr;
		}

		legend {
			position: relative;
			border: none;
			font-size: 1.25rem;
			line-height: 1.5rem;
			margin-bottom: 0.5rem;
			color: var(--brand-secondary);
			font-family: var(--brand-headline);
			font-weight: var(--font-weight-header, 400);
		}

		span {
			font-weight: normal;
		}
	}

	&.has-error {
		input[type="radio"] + label:before {
			border-color: var(--brand-error);
		}

		.checkmark {
			input[type="radio"]:not(:checked) + label {
				outline: 2px solid var(--brand-error);
			}
		}

		.help-block {
			color: var(--brand-error);
		}
	}

	&[disabled] {
		label,
		legend,
		.hint-text {
			color: var(--brown-light);
		}
	}
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
	input[type="radio"] {
		&:checked + label:before {
			filter: saturate(1000%);
		}
	}

	.checkmark {
		input:focus + label {
			outline: 5px solid;
		}
	}
}
