@import "../global.less";

.accordion {
	border: 1px solid transparent;
	border-bottom: 1px solid var(--brand-secondary-light-3);
	border-radius: 2px;
	background-color: transparent;
	display: flex;
	flex-direction: column;
	transition:
		all 0.3s ease-in-out,
		height 0.3s ease;

	&:last-child {
		margin-bottom: 0;
	}

	&.show {
		> .accordion-body {
			display: block;
		}
	}

	&.accordion-open {
		background-color: var(--white);

		&:first-child {
			border-top: 1px solid var(--brand-secondary-light-3);
		}

		> .accordion-header {
			&:before {
				transform: rotate(180deg);
			}
		}
	}

	.accordion-header {
		text-decoration: none;
		touch-action: manipulation;
		cursor: pointer;
		line-height: 1rem;
		border: 1px solid transparent;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		user-select: none;
		color: var(--brand-secondary);
		background-color: var(--white);
		padding: 1rem;
		text-align: start;
		font-weight: var(--font-weight-bold);
		font-family: var(--brand-headline);
		font-size: 1rem;

		&:before {
			color: var(--brand-secondary);
			content: "";
			position: absolute;
			right: 0.5rem;
			top: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			background-image: url(../../icons/shapes/chevron_down.svg);
			background-repeat: no-repeat;
			background-position: center;
			width: 20px;
		}
	}

	&:hover {
		.accordion-header {
			text-decoration: underline;
		}
	}

	.accordion-body {
		padding: 0 1rem;
		display: none;
	}

	.expanding {
		height: 0;
		overflow: hidden;
		transition: height 0.3s ease;
	}
}

@media (prefers-reduced-motion) {
	.accordion {
		transition: none;

		.expanding {
			transition: none;
		}
	}
}
