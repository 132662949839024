@import "../global.less";

.action-list {
	position: relative;
	display: inline-flex;

	.action-menu {
		position: absolute;
		top: 2.7rem;
		right: 0;
		z-index: var(--action-list-z-index, 450);
		overflow: hidden;
		box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.25);
		background-color: @white;
		display: none;

		> a {
			display: flex;
			padding: 0.5rem 1rem 0.3rem 0.5rem;
			visibility: hidden;
			position: relative;
			text-decoration: none;
			white-space: nowrap;
			align-items: center;
			color: var(--brand-secondary);

			> .material-icons,
			> :is(
					i[class^="at-"],
					i[class*=" at-"],
					i[class^="swepay-icon-"],
					i[class*=" swepay-icon-"]
				) {
				margin-right: 0.5rem;
			}

			&:hover,
			&:focus {
				text-decoration: none;
				background-color: @light-gray;
			}

			&:not(:last-child) {
				border-bottom: 1px solid @light-gray;
			}
		}
	}

	.action-toggle {
		position: relative;
		line-height: 1.1;
		user-select: none;
		border-radius: 2px;
		background-color: transparent;
		color: var(--brand-secondary);
		display: flex;
		padding: 0;
		border: 0;
		outline: 0;

		&:not(
				.material-icons,
				i[class^="at-"],
				i[class*=" at-"],
				i[class^="swepay-icon-"],
				i[class*=" swepay-icon-"]
			) {
			padding: 0.7rem;
		}

		&:hover,
		&:focus {
			cursor: pointer;
			background-color: @light-gray;
		}

		&:focus-visible {
			outline: 2px solid @brand-secondary;
			border-radius: 2px;
		}
	}

	&.active {
		.action-toggle {
			background-color: @light-gray;
		}

		.action-menu {
			display: table;

			> a {
				visibility: visible;
			}
		}
	}

	&.anchor-top-left {
		.action-menu {
			left: 0;
		}
	}

	&.anchor-bottom-right {
		.action-menu {
			top: auto;
			bottom: 2.7rem;
		}
	}

	&.anchor-bottom-left {
		.action-menu {
			top: auto;
			left: 0;
			bottom: 2.7rem;
		}
	}
}

@media (forced-colors: active) {
	.action-list {
		&.active {
			.action-menu {
				outline: 10px solid;

				> a {
					&:hover,
					&:focus {
						outline: 2px solid;
					}
				}
			}
		}
	}
}
