@import "../global.less";

ul.breadcrumb,
ol.breadcrumb {
	margin: 1rem 0;
	padding-left: 0;
	display: flex;

	li {
		display: flex;
		list-style: none;
		align-items: center;
		color: @brand-secondary;

		a {
			text-decoration: none;
			color: var(--brand-accent-links);
		}

		&:not(:last-child) {
			span {
				color: var(--brand-secondary);
			}
		}

		&:last-child {
			font-weight: bold;
			color: var(--brand-secondary);

			&:after {
				display: none;
			}
		}

		// TODO: not by issue since not exposed, but replace by pure CSS lines
		&:after {
			content: "chevron_right";

			.material-icons-styling();
		}
	}

	.material-icons,
	:is(
			i[class^="at-"],
			i[class*=" at-"],
			i[class^="swepay-icon-"],
			i[class*=" swepay-icon-"]
		) {
		font-size: 1rem;
		vertical-align: text-top;
	}
}

@media screen and (max-width: @screen-xs-max) {
	ul.breadcrumb,
	ol.breadcrumb {
		display: block;

		li {
			display: inline-block;
		}
	}
}
