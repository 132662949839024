/* stylelint-disable declaration-no-important, block-opening-brace-space-before */

/*
 * Utilities for common `display` values
 */

each(@grid-breakpoints, .(@size, @abbr) {
    @ruleset: {
        .d@{infix}-none           { display: none !important; }
        .d@{infix}-inline         { display: inline !important; }
        .d@{infix}-inline-block   { display: inline-block !important; }
        .d@{infix}-block          { display: block !important; }
        .d@{infix}-table-row      { display: table-row !important; }
        .d@{infix}-table-cell     { display: table-cell !important; }
        .d@{infix}-flex           { display: flex !important; }
        .d@{infix}-inline-flex    { display: inline-flex !important; }
        .d@{infix}-grid           { display: grid !important; }
        .d@{infix}-inline-grid    { display: inline-grid !important; }
    }

    .media-breakpoint-up(@size, @abbr, @ruleset);
});

/*
 * Utilities for toggling `display` in print
 */

@media print {
	.d-print-none {
		display: none !important;
	}
	.d-print-inline {
		display: inline !important;
	}
	.d-print-inline-block {
		display: inline-block !important;
	}
	.d-print-block {
		display: block !important;
	}
	.d-print-table-row {
		display: table-row !important;
	}
	.d-print-table-cell {
		display: table-cell !important;
	}
	.d-print-flex {
		display: flex !important;
	}
	.d-print-inline-flex {
		display: inline-flex !important;
	}
}
