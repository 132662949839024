@import "../global.less";
@import "./badge.less";

a {
	color: var(--brand-accent-links);

	&:hover,
	&:focus {
		color: color-mix(in srgb, var(--brand-accent-links), #000000 25%);
		text-decoration: none;
	}

	&:active,
	&:hover {
		outline: 0;
	}

	&:focus {
		.tab-focus();
	}

	&.bright {
		color: var(--blue-syntax-highlight);

		&:focus-visible {
			outline: 2px solid var(--brand-primary);
			border-radius: 2px;
		}
	}

	&.icon-link {
		text-decoration: none;
		display: inline-flex;
		align-items: baseline;

		&:hover,
		:focus {
			span {
				text-decoration: underline;
			}
		}

		i {
			font-size: 1rem;
			line-height: 1.5;
			align-self: center;

			&.material-icons {
				align-self: flex-end;
			}
		}

		&.small {
			i {
				font-size: 0.875rem;
			}
		}
	}

	&.action-link {
		position: relative;
		display: flex;
		align-items: center;
		min-height: 3rem;
		padding-left: 1rem;
		border: 1px solid transparent;
		text-decoration: none;
		background-color: var(--bg-primary);
		color: var(--brand-secondary);
		margin-bottom: 1rem;
		border-radius: 4px;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;

		.action-link-content {
			margin-right: 2rem;
		}

		.action-link-label {
			font-size: 1.125rem;
			font-family: var(--brand-headline);
			margin-right: 0.25rem;
		}

		&:focus,
		&:hover {
			h4 {
				text-decoration: underline;
			}
		}

		&:focus-visible {
			outline: 2px solid var(--brand-secondary);
			outline-offset: 0;
			border-radius: 2px;
		}

		h4 {
			font-family: var(--brand-headline);
		}

		&.badge {
			margin-left: 0.25rem;
		}

		&.multiline {
			.small-text {
				text-decoration: none;
			}
		}

		&:before {
			content: "";
			position: absolute;
			height: 100%;
			width: 0.25rem;
			left: 0;
			top: 0;
			background-color: var(--brand-primary);
			border-radius: 0.25rem 0 0 0.25rem;
		}

		&:after {
			color: var(--brand-secondary);
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			display: flex;
			align-items: center;
			background-image: url("../../icons/shapes/chevron_down.svg");
			transform: rotate(-90deg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			width: 24px;
			height: auto;
			margin: 0 0.5rem;
		}

		&.action-link-new-tab {
			&:after {
				background-image: url("../../icons/shapes/export_arrow_up_right.svg");
				transform: none;
			}
		}
	}

	.action-link-multiline {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		margin-right: 1.5rem;

		.small-text {
			padding-left: 0;
		}
	}

	.small-text {
		font-size: 0.75rem;
		color: var(--brand-secondary);
		padding-left: 0.5rem;
	}
}

@media (forced-colors: active) {
	.a {
		border: 3px solid;
	}

	&.action-link {
		&:before {
			border: 2px solid;
		}

		&:focus-visible {
			outline: 2px solid transparent;
		}
	}
}
