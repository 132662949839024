/* stylelint-disable block-opening-brace-space-before, declaration-no-important */

.border {
	border: 1px solid @border-color !important;
}
.border-top {
	border-top: 1px solid @border-color !important;
}
.border-right {
	border-right: 1px solid @border-color !important;
}
.border-bottom {
	border-bottom: 1px solid @border-color !important;
}
.border-left {
	border-left: 1px solid @border-color !important;
}

.border-top-brand {
	border-top: 0.375rem solid @brand-primary;
}

.border-top-radius(@radius) {
	border-top-right-radius: @radius;
	border-top-left-radius: @radius;
}

.border-right-radius(@radius) {
	border-bottom-right-radius: @radius;
	border-top-right-radius: @radius;
}

.border-bottom-radius(@radius) {
	border-bottom-right-radius: @radius;
	border-bottom-left-radius: @radius;
}

.border-left-radius(@radius) {
	border-bottom-left-radius: @radius;
	border-top-left-radius: @radius;
}
