/* Text color list */

/* stylelint-disable block-opening-brace-space-before, declaration-no-important */

// Alignment

.text-justify {
	text-align: justify !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

// Font family

.swed-emphasized {
	font-family: var(--brand-headline);
	font-weight: var(--font-weight-normal);
}

// Responsive alignment

each(@grid-breakpoints, .(@size, @abbr) {
    @ruleset: {
        .text@{infix}-left           { text-align: left !important; }
        .text@{infix}-right          { text-align: right !important; }
        .text@{infix}-center         { text-align: center !important; }
    }

    .media-breakpoint-up(@size, @abbr, @ruleset);
});

// Transformation

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.text-underline {
	text-decoration: underline !important;
}

// Weight and italics
.font-weight-light {
	font-weight: var(--font-weight-light) !important;
}

.font-weight-normal {
	font-weight: var(--font-weight-normal) !important;
}

.font-weight-bold {
	font-weight: var(--font-weight-bold) !important;
}

.font-italic {
	font-style: italic !important;
}

// Contextual colors

@color-list: {
	default: @text-color;
	black: @black;
	white: @white;
	brand: @brand-primary;
	success: @brand-success;
	neutral: @brand-info;
	warning: @brand-warning;
	danger: @brand-error;
	banner: @brand-primary-light;
};

.color-variant(@parent, @color) {
	.@{parent} {
		color: @color !important;

		a&:hover {
			color: darken(@color, 10%) !important;
		}

		&.text-white,
		.text-white {
			color: #ffffff !important;
			border-radius: 2px;
		}
	}
}

each(@color-list, .(@color, @name) {
    .color-variant(~"text-@{name}", @color);
});

.text-muted {
	color: var(--text-muted) !important;
}

.text-black-50 {
	color: fade(@black, 50%) !important;
}

.text-white-50 {
	color: fade(@white, 50%) !important;
}

// Misc

.text-decoration-none {
	text-decoration: none !important;
}

// Reset

.text-reset {
	color: inherit !important;
}

// Highlight

.text-highlight {
	border-bottom: 4px solid @brand-primary;
}

// Dark background

.text-dark-background {
	background-color: @brand-secondary;
}
