@import "../global.less";

.alert {
	--color-icon: var(--brand-info);
	--color-bg: var(--brand-info-light);

	padding: 1rem;
	position: relative;
	margin-top: 0;
	margin-bottom: 1rem;
	display: flex;
	color: var(--black);
	border-radius: 0.25rem;
	background-color: var(--color-bg);

	& > i[aria-hidden="true"]:first-child {
		color: var(--color-icon);
		margin-right: 1rem;
	}

	&.alert-success {
		--color-icon: var(--system-success);
		--color-bg: var(--system-success-light);
	}

	&.alert-warning {
		--color-icon: var(--system-warning);
		--color-bg: var(--system-warning-light);
	}

	&.alert-danger {
		--color-icon: var(--system-error);
		--color-bg: var(--system-error-light);
	}

	&.alert-informative {
		--color-icon: var(--system-info);
		--color-bg: var(--system-info-light);
	}

	> p {
		margin-bottom: 0;
		margin-top: 0;
	}

	.alert-close {
		all: unset;
		align-self: baseline;
		margin-left: 0.5rem;

		&:hover {
			background-color: var(--bg-grey);
		}
		.close-button();

		&:focus {
			border: none;
			border-radius: 0.125rem;
			box-shadow: 0 0 0 2px var(--black);
			outline: 2px solid;
		}
	}
}

@media (forced-colors: active) {
	.alert {
		border: 10px solid;

		.alert-close {
			&:focus {
				outline: 2px solid;
			}
		}
	}
}
