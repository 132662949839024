@code-tags-padding: 2px 6px;
@code-tags-border-radius: 3px;

code {
	padding: @code-tags-padding;
	border-radius: @code-tags-border-radius;
	font-family: "Akkurat Mono", monospace;
	font-size: 0.875rem;
	line-height: 1.25rem;
	white-space: nowrap;
	background-color: @brand-bg-2;
}

.code-tags {
	padding: @code-tags-padding;
	border-radius: @code-tags-border-radius;
	font-family: "Akkurat Mono", monospace;
	font-size: 0.875rem;
	line-height: 1.25rem;
	white-space: nowrap;

	&.code-tags-primary {
		background-color: @brand-bg-2;
	}

	&.code-tags-secondary {
		background-color: var(--bg-primary);
	}

	&.code-tags-tertiary {
		background-color: @brand-bg-gray;
	}
}

.tag {
	display: inline-flex;
	padding: @code-tags-padding;
	font-size: 1rem;
	border-radius: @code-tags-border-radius;
	background-color: @brand-bg-2;
	margin-right: 0.5rem;

	&.tag-secondary {
		background-color: var(--bg-primary);
	}

	&.tag-tertiary {
		background-color: @brand-bg-gray;
	}

	&.removable {
		padding-right: 0;
	}

	button.close-button {
		background-color: transparent;
		border: none;
		display: grid;
		cursor: pointer;
		width: 1.5rem;
		padding: 0;
		margin-left: 0.125rem;

		// conditional check to ensure no duplicate close cross if not done migration from close icon element to no icon element needed
		// maybe can remove it in next major release or better next real rewrite with breaking changes
		&:not(:has(i)) {
			&:before,
			&:after {
				content: "";
				width: 12px;
				height: 1.5px;
				background-color: var(--black);
				place-self: center;
				position: absolute;
			}

			&:before {
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(-45deg);
			}
		}

		// TODO: Remove this for next major release 11.0.0 - we'll now be using before & after pseudo elements, no need for close icon anymore. Or keep until introduction of necessary breaking changes, since no big deal anyway
		i {
			font-size: 1rem;
		}

		&:hover {
			background-color: rgba(0, 0, 0 / 10%);
			border-radius: 0.125rem;
		}

		&:focus {
			border: 2px solid transparent;
			border-radius: 0.125rem;
			box-shadow: 0 0 0 0.125rem @black;
			outline: none;
		}
	}
}

@media (forced-colors: active) {
	.tag {
		border: 1px solid;
	}
}
