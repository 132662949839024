/* stylelint-disable declaration-no-important */
@properties: {
	width: ~"w";
	height: ~"h";
};

each(@properties, .(@abbrev, @prop) {
    each(@sizes, .(@length, @size) {
        .@{abbrev}-@{size} { @{prop}: @length !important; }
    });
});

.mw-100 {
	max-width: 100% !important;
}

.max-w-initial {
	max-width: initial !important;
}

.min-w-initial {
	min-width: initial !important;
}

.mh-100 {
	max-height: 100% !important;
}

// Viewport additional helpers

.min-vw-100 {
	min-width: 100vw !important;
}
.min-vh-100 {
	min-height: 100vh !important;
}

.vw-100 {
	width: 100vw !important;
}
.vh-100 {
	height: 100vh !important;
}
