@import "../global.less";

.expandable {
	border: none;
	outline: 1px solid var(--brand-secondary-light-3);
	border-radius: var(--border-radius-expandable);
	margin-bottom: 1rem;
	background-color: var(--white);
	display: flex;
	position: relative;
	flex-direction: column;
	transition: height 0.3s ease;

	&:last-child {
		margin-bottom: 0;
	}

	&:after {
		content: "";
		position: absolute;
		background-color: transparent;
		height: 4px;
		left: -1px;
		right: -1px;
		top: -1px;
		transition: background-color 0.3s ease-in-out;
	}

	&.show {
		.expandable-body {
			display: block;
		}
	}

	&.expandable-open {
		.expandable-header .expandable-headline:before {
			transform: rotate(180deg);
		}
	}

	&:focus-within {
		border: none;
		border-radius: var(--border-radius-expandable);
		outline: 2px solid var(--brand-secondary);
	}

	.expandable-header {
		font-weight: var(--font-weight-normal);
		font-family: var(--brand-headline);
		text-decoration: none;
		vertical-align: middle;
		touch-action: manipulation;
		cursor: pointer;
		line-height: 1rem;
		border: 1px solid transparent;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		color: @brand-secondary;
		padding: 1rem;
		text-align: start;
		background-color: var(--white);
		border-radius: var(--border-radius-expandable);

		&.icon {
			display: grid;
			grid-template-columns: max-content auto;
			grid-template-areas:
				"icon headline"
				"icon subtitle";
			gap: 0 1rem;
		}

		.material-icons,
		:is(
				i[class^="at-"],
				i[class*=" at-"],
				i[class^="swepay-icon-"],
				i[class*=" swepay-icon-"]
			) {
			grid-area: icon;
		}

		.expandable-headline {
			font-size: 1.125rem;
			font-family: var(--brand-headline);
			line-height: 1.5rem;
			font-weight: var(--font-weight-normal);
			grid-area: headline;
			margin: 0;
			position: relative;

			&:before {
				color: var(--brand-secondary-light-2);
				content: "";
				position: absolute;
				right: 0;
				font-size: 1.75rem;
				height: 1.75rem;
				width: 1.75rem;
				background-color: currentColor;
				mask: url("../../icons/shapes/chevron_down.svg") center no-repeat;
				margin: 0 0 0 1rem;
			}
		}

		.expandable-subtitle {
			font-family: var(--font-stack);
			font-size: 0.875rem;
			font-weight: var(--font-weight-normal);
			line-height: 1rem;
			grid-area: subtitle;
		}
	}

	.expandable-body {
		padding: 0 1rem;
		display: none;
	}

	.expanding {
		height: 0;
		overflow: hidden;
		transition: height 0.3s ease;
	}
}

@media (prefers-reduced-motion) {
	.expandable {
		transition: none;

		&:after {
			transition: none;
		}

		.expanding {
			transition: none;
		}
	}
}
