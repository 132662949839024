.img-fluid {
	max-width: 100%;
	height: auto;
}

.img-rounded {
	border-radius: 0.125rem;
}

.img-circle {
	border-radius: 50%;
}

.img-thumbnail {
	padding: var(--base-padding-xs);
	background-color: @white;
	border: 1px solid @list-gray;
	border-radius: 0.125rem;
	max-width: 100%;
	height: auto;
}

.object-fit-cover {
	object-fit: cover;
}
