@import "../global.less";

// So far the Toast container is set entirely via our script we ship.
// This means we can introduce breaking changes inside the toast as we please. As long as it is not breaking event listeners for example.
#toast-container {
	--toast-height: 3.5rem;
	--toast-color: var(--white);
	--toast-text-color: var(--text-color);
	--toast-action-color: var(--brand-secondary);

	display: block;
	position: fixed;
	z-index: var(--toast-z-index, 700);
	max-width: 21.4rem;
	top: 1rem;
	left: 0;
	right: 0;
	margin: 0 auto;

	@media screen and (min-width: @screen-sm-min) {
		left: auto;
		right: 1%;
	}

	.toast {
		--color-icon: var(--black);

		position: relative;
		display: flex;
		min-height: var(--toast-height);
		padding: 1rem var(--base-padding-lg);
		background-color: var(--toast-color);
		color: var(--toast-text-color);
		border-radius: 0.25rem;
		font-weight: 300;
		line-height: 1.5;
		cursor: default;
		box-shadow: 1px 2px 10px rgb(0 0 0 / 15%);
		opacity: 1;
		animation: slide-in-from-top-right 0.5s forwards;

		@keyframes slide-in-from-top-right {
			from {
				right: -1000px;
			}

			to {
				right: 0;
			}
		}

		@media screen and (max-width: @screen-sm-min) {
			animation: slide-in-from-top 0.5s forwards;

			@keyframes slide-in-from-top {
				from {
					top: -1000px;
				}

				to {
					top: 0;
				}
			}
		}

		&.fade {
			opacity: 0;
			transition: opacity 0.3s linear;
		}

		&:not(:first-child) {
			margin-top: 10px;
		}

		> i {
			color: var(--color-icon);
		}

		&.toast-success {
			--color-icon: var(--system-success);
		}

		&.toast-neutral {
			--color-icon: var(--system-info);
		}

		&.toast-warning {
			--color-icon: var(--system-warning);
		}

		&.toast-danger {
			--color-icon: var(--system-error);

			color: var(--brand-error);
		}

		.toast-content {
			p {
				margin: 0;
			}
		}

		> i:first-child:is(
				.material-icons,
				[class^="swepay-icon-"],
				[class*=" swepay-icon-"]
			) {
			margin-right: calc(var(--base-margin-sm) * 2);
			margin-left: calc(0 - var(--base-margin-sm));
		}

		.toast-close {
			all: unset;
			display: inline-flex;
			height: fit-content;
			order: 1;
			margin-left: 0.5rem;
			border-radius: 0.175rem;

			&:hover {
				background-color: @brand-bg-gray;

				& i {
					color: @dark-brown;
				}
			}

			&:focus {
				outline: 2px solid var(--brand-secondary);
			}

			i {
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				width: 1.5rem;
				height: 1.5rem;
				margin-right: calc(0 - var(--base-margin-sm));
				color: @medium-brown;
			}
		}

		.toast-action {
			color: var(--toast-action-color);
			font-weight: 500;

			&.btn {
				background-color: var(--toast-color);
				margin-bottom: 0;
				padding: 0;
			}
		}

		@media screen and (max-width: @screen-sm-max) {
			width: 100%;
			border-radius: 0;
		}
	}
}

@media (forced-colors: active) {
	#toast-container {
		.toast {
			border: 10px solid;

			.toast-close {
				&:focus {
					i {
						outline: 2px solid;

						&:hover {
							outline: 4px solid;
						}
					}
				}
			}
		}
	}
}
