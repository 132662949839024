.logotype-vertical {
	&.logotype-xs {
		width: 1.5rem;
	}

	&.logotype-sm {
		width: 2rem;
	}

	&.logotype-md {
		width: 2.5rem;
	}

	&.logotype-lg {
		width: 3rem;
	}
}

.logotype-horizontal {
	&.logotype-xs {
		height: 1.5rem;
	}

	&.logotype-sm {
		height: 2rem;
	}

	&.logotype-md {
		height: 2.5rem;
	}

	&.logotype-lg {
		height: 3rem;
	}
}
