.loader {
	display: inline-block;
	width: 3rem;
	height: 3rem;
	margin-left: 0.5rem;
	border: 0.25rem solid #512b2b66;
	border-left-color: @brand-secondary;
	border-radius: 50%;
	animation: loader-spin 0.8s linear infinite;

	&.loader-center {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	&.loader-sm {
		width: 1.5rem;
		height: 1.5rem;
	}

	&.loader-inverted {
		border-color: #ffffff66;
		border-left-color: @white;
	}
}

@keyframes loader-spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
